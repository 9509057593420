import Moment from "moment";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";

const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const OUTAGE_NONE = "NONE";
const OUTAGE_PRE = "PRE";
const OUTAGE_POST = "POST";

const ENV_BETA = "beta";
const ENV_PROD = "production";
const ENV_DEV = "development";

export const workTimeMap = {
  regular: "RT",
  overtime: "OT",
  standBy: "SBT",
  safety: "SF",
  double: "DT",
  guaranteed: "GUARANTEED",
  holiday: "HT",
  mileage: "Daily Mileage",
};

const NONJOB = "NONJOB";

export const workNonJobTimeMap = {
  regular: "Regular",
  overtime: "Overtime",
  safety: "Safety",
  holiday: "Holiday",
  mileage: "Daily Mileage",
};

const STATUS_APPROVED = "approved";
const STATUS_DECLINED = "declined";

const WO_TYPE_GROUP = "group";

const READ_ONLY_USER_ROLE = "Read Only";
const EXECUTIVE_USER_ROLE = "Executive";

export const utilsHelper = {
  isExecutive: (authContext) =>
    authContext.currentUser.role.name === EXECUTIVE_USER_ROLE,
  isReadOnly: (authContext) =>
    authContext.currentUser.role.name === READ_ONLY_USER_ROLE,
  getExpenseStatusText: (expense) =>
    expense.status === STATUS_DECLINED
      ? `Declined by ${
          expense.declinedAuthor
            ? `${expense.declinedAuthor.firstName} ${
                expense.declinedAuthor.lastName
              } at ${utilsHelper.formatDateTime(expense.declinedAt)}`
            : "N/A"
        }`
      : expense.status === STATUS_APPROVED
      ? `Approved by ${
          expense.approvedAuthor
            ? `${expense.approvedAuthor.firstName} ${
                expense.approvedAuthor.lastName
              } at ${utilsHelper.formatDateTime(expense.approvedAt)}`
            : "N/A"
        }`
      : null,
  getCurrentEnvironment: () =>
    process.env.REACT_APP_ENV === ENV_BETA
      ? "Beta"
      : process.env.REACT_APP_ENV === ENV_DEV
      ? "Development"
      : null,
  buildQueryString: (data) =>
    Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&"),
  workOrderAddress: (workOrder) => {
    if (!workOrder) {
      return "Not Provided";
    }
    if (workOrder?.customer) {
      return `
                ${workOrder.customer.SHIP_ADDRESS1}, ${workOrder.customer.SHIP_CITY} ${workOrder.customer.SHIP_STATE} ${workOrder.customer.SHIP_ZIP}
            `;
    }
    return `${workOrder?.addressLine}, ${workOrder?.city} ${workOrder?.state} ${workOrder?.zip}`;
  },
  isBetaProd: () =>
    process.env.REACT_APP_ENV === ENV_BETA ||
    process.env.REACT_APP_ENV === ENV_PROD,
  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number),
  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("es-ES", { maximumFractionDigits }).format(number),
  formatPercent: (progress, total) =>
    `${total ? parseFloat((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format = "YYYY-MM-DD") =>
    (date ? moment(date) : moment()).format(format),
  formatDateJobScope: (date, format = "YYYY-MM-DD") => {
    return date ? moment(date).format(format) : "-";
  },
  formatDateTime: (date, format = "YYYY-MM-DD, h:mm a") =>
    (date ? moment(date) : moment()).format(format),
  capitalize: (text) =>
    text ? text.charAt(0).toUpperCase() + text.toLowerCase().slice(1) : "",
  nameInitials: (name) => {
    if (!/(.+)( )+(.+)/.test(name)) {
      return `${name[0]}${name[1] || ""}`;
    }
    const [, , , lastname] = name.match(/(.+)( )+(.+)/);
    return `${name[0]}${lastname[0]}`;
  },
  outageLabels: (outage) => {
    if (outage === OUTAGE_NONE || outage === null) {
      return "Outage";
    }
    if (outage === OUTAGE_PRE) {
      return "Pre-Outage";
    }
    if (outage === OUTAGE_POST) {
      return "Post-Outage";
    }
  },
  equipmentLabels: (equipment) => {
    if (equipment === "gangBoxAndToolsQty") {
      return "Gang Box And Tools Qty";
    }
    if (equipment === "toolTrail") {
      return "Tool Trail";
    }
    if (equipment === "toolTruck") {
      return "Tool Truck";
    }
    if (equipment === "laserAlignmentQty") {
      return "Laser Alignment Qty";
    }
    if (equipment === "gasMonitorQty") {
      return "Gas Monitor Qty";
    }
    return equipment;
  },
  calculateScheduleStartDate: (dayShiftStartDate, nightShiftStartDate) => {
    if (dayShiftStartDate !== null && nightShiftStartDate === null) {
      return dayShiftStartDate;
    }
    if (dayShiftStartDate === null && nightShiftStartDate !== null) {
      return nightShiftStartDate;
    }
    return moment(dayShiftStartDate).isBefore(nightShiftStartDate, "days")
      ? dayShiftStartDate
      : nightShiftStartDate;
  },
  calculateScheduleEndDate: (dayShiftEndDate, nightShiftEndDate) => {
    if (dayShiftEndDate !== null && nightShiftEndDate === null) {
      return dayShiftEndDate;
    }
    if (dayShiftEndDate === null && nightShiftEndDate !== null) {
      return nightShiftEndDate;
    }
    return moment(dayShiftEndDate).isAfter(nightShiftEndDate, "days")
      ? dayShiftEndDate
      : nightShiftEndDate;
  },
  getTimes: (workTimes) => {
    const times = [];
    if (workTimes?.regular?.hours > 0) {
      times.push(`${parseFloat(workTimes.regular.hours).toFixed(2)} RT`);
    }
    if (workTimes?.overtime?.hours > 0) {
      times.push(`${parseFloat(workTimes.overtime.hours).toFixed(2)} OT`);
    }
    if (workTimes?.standByTime?.hours > 0) {
      times.push(`${parseFloat(workTimes.standByTime.hours).toFixed(2)} SBT`);
    }
    if (workTimes?.safetyTime?.hours > 0) {
      times.push(`${parseFloat(workTimes.safetyTime.hours).toFixed(2)} ST`);
    }
    if (workTimes?.doubleTime?.hours > 0) {
      times.push(`${parseFloat(workTimes.doubleTime.hours).toFixed(2)} DT`);
    }
    if (workTimes?.holidayTime?.hours > 0) {
      times.push(`${parseFloat(workTimes.holidayTime.hours).toFixed(2)} HT`);
    }
    return times;
  },
  getPayollTimes: (workTimes) => {
    const times = [];
    if (workTimes.REG > 0) {
      times.push(`${parseFloat(workTimes.REG).toFixed(2)} RT`);
    }
    if (workTimes.OT > 0) {
      times.push(`${parseFloat(workTimes.OT).toFixed(2)} OT`);
    }
    if (workTimes.ST > 0) {
      times.push(`${parseFloat(workTimes.ST).toFixed(2)} SBT`);
    }
    if (workTimes.SFTR > 0) {
      times.push(`${parseFloat(workTimes.SFTR).toFixed(2)} ST`);
    }
    if (workTimes.DT > 0) {
      times.push(`${parseFloat(workTimes.DT).toFixed(2)} DT`);
    }
    return times.join(", ");
  },
  workTimeMapper: (type) => workTimeMap[type] || "-",
  sortDays: (a, b) =>
    parseInt(b.replaceAll("-", "")) - parseInt(a.replaceAll("-", "")),
  formatPhone: (phone) => {
    const cleaned = ("" + phone).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  },
  customizeToolbarReport: (toolbar, pivot, filename) => {
    const exportHandler = (params) => {
      if (
        params.type === "excel" &&
        params.config.filename.includes("PayCom")
      ) {
        const flexmonster = params.pivotRef.current.flexmonster;

        const config = {
          ...params.config,
          destinationType: "plain",
        };

        flexmonster.exportTo(params.type, config, async (res) => {
          const currentWorkbook = new ExcelJS.Workbook();
          const dataExcel = await currentWorkbook.xlsx.load(res.data.buffer);
          const currentWorksheet = dataExcel.getWorksheet(1);

          const columns = {};
          const data = [];

          currentWorksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 2) {
              row.eachCell((cell) => {
                const onlyLetter = cell.address.replace(/[0-9]/g, "");
                columns[onlyLetter] = cell.value;
              });
            }

            if (rowNumber > 2) {
              const rowData = {};
              row.eachCell((cell) => {
                const onlyLetter = cell.address.replace(/[0-9]/g, "");
                rowData[onlyLetter] = cell.value;
              });
              data.push(rowData);
            }
          });

          const newWorkbook = new ExcelJS.Workbook();
          const newWorksheet = params.config.filename.includes("PayCom_Check_")
            ? newWorkbook.addWorksheet("PayCom Check")
            : newWorkbook.addWorksheet("PayCom Upload");
          const columnMapping = params.config.filename.includes("PayCom_Upload")
            ? {
                "EE Code": "A",
                "1 for Earning - 2 for Tax - 3 for Deduction": "B",
                "Earning/Deduction Code": "C",
                "Hours or Amount": "D",
              }
            : {
                "EE Code": "A",
                "Employee Name": "B",
                "1 for Earning - 2 for Tax - 3 for Deduction": "C",
                "Earning/Deduction Code": "D",
                "Hours or Amount": "E",
              };

          data.forEach((rowData) => {
            const row = newWorksheet.addRow([]);
            Object.keys(columnMapping).forEach((columnName) => {
              const columnLetter = columnMapping[columnName];
              const columnValue = rowData[columnLetter];
              row.getCell(columnLetter).value = columnValue;
            });
          });

          newWorkbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = params.config.filename;
            a.click();
          });
        });
      } else if (params.type === "pdf") {
        params.pivotRef.current.flexmonster.exportTo(params.type, {
          ...params.config,
        });
      } else {
        if (params.config.filename.includes("PayCom")) {
          params.config.header = "";
        }
        params.pivotRef.current.flexmonster.exportTo(
          params.type,
          params.config
        );
      }
    };

    const tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      tabs[3].menu[1].handler = exportHandler;
      tabs[3].menu[1].args = {
        pivotRef: pivot,
        type: "html",
        config: {
          filename,
        },
      };
      tabs[3].menu[2].handler = exportHandler;
      tabs[3].menu[2].args = {
        pivotRef: pivot,
        type: "csv",
        config: {
          filename,
        },
      };
      tabs[3].menu[3].handler = exportHandler;
      tabs[3].menu[3].args = {
        pivotRef: pivot,
        type: "excel",
        config: {
          filename,
        },
      };
      tabs[3].menu[4].handler = exportHandler;
      tabs[3].menu[4].args = {
        pivotRef: pivot,
        type: "image",
        config: {
          filename,
        },
      };
      tabs[3].menu[5].handler = exportHandler;
      tabs[3].menu[5].args = {
        pivotRef: pivot,
        type: "pdf",
        config: {
          filename,
        },
      };
      return tabs;
    };
  },
  determineArticle: (word) => {
    const lowerCaseWord = word.toLowerCase();
    const wordsStartingWithVowelSound = ["a", "e", "i", "o", "u"];
    if (wordsStartingWithVowelSound.includes(lowerCaseWord.charAt(0))) {
      return "an";
    } else {
      return "a";
    }
  },
  getWorkOrderNumberWithGrouped: (workOrder) => {
    if (workOrder.type !== WO_TYPE_GROUP) {
      return workOrder.workOrderNumber || "-";
    }
    return workOrder.groupedWorkOrders?.length
      ? workOrder.groupedWorkOrders.map((w) => w.workOrderNumber).join(", ")
      : "Group";
  },
  getEntryWorkOrderNumber: (entry) => {
    if (entry.workOrderNumber) {
      return entry.workOrderNumber === "N/A"
        ? "Uncoded Time"
        : entry.workOrderNumber;
    }
    if (entry.workOrder?.workOrderNumber) {
      return entry.workOrder.workOrderNumber === "N/A"
        ? "Uncoded Time"
        : entry.workOrder.workOrderNumber;
    }
    return "N/A";
  },
  getWorkOrderDescription: (workOrder) => {
    if (workOrder.type !== WO_TYPE_GROUP) {
      return workOrder.drawName || "-";
    }
    return workOrder.groupedWorkOrders?.length
      ? workOrder.groupedWorkOrders.map((w) => w.drawName).join(", ")
      : "Group";
  },
  getWorkOrderStatusById: (workOrderStatuses, statusId) =>
    workOrderStatuses.workOrderStatus?.find((s) => s.id === parseInt(statusId))
      ?.status || "",
  employeeHasConflict: (workOrderStatuses, workOrder, employeeCrews) => {
    const { startDate, endDate, id } = workOrder;
    const assignations = employeeCrews
      .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
      .map((employeeCrew) => employeeCrew.crew)
      .flatMap((crew) => crew.crewWorkOrders)
      .map((crewWorkOrder) => crewWorkOrder.vwworkOrder)
      .filter((otherWo) => {
        const status = utilsHelper.getWorkOrderStatusById(
          workOrderStatuses,
          otherWo.statusId
        );
        const isComplete =
          workOrderStatuses.COMPLETED_STATUSES.indexOf(status) > -1;
        return !isComplete;
      });
    const intersects = assignations.filter((otherWo) => {
      const range1 = moment.range(startDate, endDate || moment());
      const range2 = moment.range(
        otherWo.startDate,
        otherWo.endDate || moment()
      );
      return range1.overlaps(range2, { adjacent: true }) && otherWo.id !== id;
    });
    return intersects;
  },
  employeeAlreadyAdded: (employee, workOrderCrews) =>
    workOrderCrews?.find((workOrderCrew) =>
      workOrderCrew.employees.find((e) => e.id === employee.id)
    ),
  getAvailableSlots: (workOrderCrews) => {
    return workOrderCrews.filter(
      (workOrderCrew) =>
        workOrderCrew.employees.length <
        workOrderCrew.count + (workOrderCrew.overageCount || 0)
    );
  },
  getEmployeesOptions: (employeesOptions) => [
    ...(employeesOptions || []).map((e) => ({
      label: `${e.firstName} ${e.lastName}`,
      value: e.id,
    })),
  ],
  getEmployeesOptionsWithPreferredName: (
    employeesOptions,
    assignedEmployees = []
  ) => {
    const result = [
      ...(employeesOptions || [])
        .filter(
          (employee) =>
            !assignedEmployees.find(
              (assignedEmployee) => employee.id === assignedEmployee.id
            )
        )
        .map((e) => ({
          label: utilsHelper.getEmployeeLabelWithPreferredName(e),
          value: e.id,
        })),
    ];
    return result;
  },
  getEmployeeOptionWithPreferredName: (employee) =>
    employee
      ? {
          label: utilsHelper.getEmployeeLabelWithPreferredName(employee),
          value: employee.id,
        }
      : null,
  getEmployeeLabelWithPreferredName: (employee) =>
    `${employee.firstName} ${
      employee.preferredFirstName
        ? `(${employee.preferredFirstName}) ${employee.lastName}`
        : employee.lastName
    }`,
  getEmployeeReverseLabelWithPreferredName: (employee) =>
    `${employee.lastName}, ${
      employee.preferredFirstName
        ? `(${employee.preferredFirstName}) ${employee.firstName}`
        : employee.firstName
    }`,
  getRolesOptions: (rolesOptions) => [
    ...(rolesOptions || []).map((e) => ({
      label: e.name,
      value: e.id,
    })),
  ],
  getRoleValue: (role) =>
    role
      ? {
          label: role.name,
          value: role.id,
        }
      : null,
  isEmployeeCrewActiveOnDate: (
    employeeCrew,
    date,
    strict = utilsHelper.isBetaProd() // in dev not strict
  ) => {
    const isCreatedBefore =
      strict && date
        ? moment(employeeCrew.createdAt).isSameOrBefore(moment(date), "date")
        : true;
    const isDisabledAfter =
      !employeeCrew.disabledAt ||
      (date
        ? moment(employeeCrew.disabledAt).isAfter(moment(date), "date")
        : false);
    const isEmployeeCrewActiveOnDate = isCreatedBefore && isDisabledAfter;
    return isEmployeeCrewActiveOnDate;
  },
  getSupervisorEmployee: (employees) => {
    //the one on date and if not, current
    return (
      employees.find((e) => e.isCrewLead && e.activeOnDate) ||
      employees.find((e) => e.isCrewLead && !e.disabledAt)
    );
  },
  adjustTableSizes: (flexmonster) => {
    const cells = document.querySelectorAll(".fm-cell"); //get all cells
    const highCells = [...cells].filter(
      (cell) => cell.clientHeight < cell.scrollHeight
    ); //find cells which "scrollHeight" property is bigger than actual height.
    const tableSizes = {
      rows: [],
      columns: [],
    };
    if (highCells.length) {
      highCells.forEach((cell) => {
        //compose the Table Sizes Object
        tableSizes.rows.push({
          idx: +cell.getAttribute("data-r"),
          height: cell.scrollHeight,
        });
        tableSizes.columns.push({
          idx: +cell.getAttribute("data-c"),
          width: cell.clientWidth,
        });
      });
      flexmonster.setTableSizes(tableSizes); //apply table sizes
    }
  },
  isEmployeeTruckDriver: (employeeCrew, asAssigned, asNative) => {
    if (!asAssigned && !asNative) {
      return false;
    }
    let result = true;
    if (asAssigned) {
      result = result && employeeCrew.role.name === "Truck Driver";
    }
    if (asNative) {
      result = result && employeeCrew.role.name === "Truck Driver";
    }
    return result;
  },
  downloadFile: async (url, imageName = "image.png") => {
    const secureURL = url.replace("http://", "https://");
    FileSaver.saveAs(secureURL, imageName);
  },
  formatMileage: (mileage, toFixed) => `${mileage.toFixed(toFixed)} m.`,
  sortCrew: (employeeCrews) => {
    /*
    PBS-2363
    */
    const crewLead = employeeCrews.filter((ec) => ec.isCrewLead);
    let res = [...crewLead];
    const otherRoles = employeeCrews
      .filter((ec) => !res.find((item) => item.id === ec.id))
      .sort((a, b) => (a.employee.firstName < b.employee.firstName ? -1 : 1));
    res = [...res, ...otherRoles];
    return res;
  },
  isNonJobWorkOrderType: (workOrderDetails) =>
    workOrderDetails.workOrder.workOrderType === NONJOB,
  mapFilesToAttachmentInfo: (files) => {
    const infos = [];
    for (let i = 0; i < files.length; i++) {
      infos.push({
        id: files[i].id,
        name: files[i].name.split(".pdf")[0],
        file: files[i].file,
        injuryReportId: files[i].injuryReportId,
      });
    }
    return infos;
  },
  aOrAn: (word) => {
    const firstWord = word.toLowerCase().split(" ")[0];
    const anRegex = /^[aeiou]|^[h](?:onest|our|onor|eir|erb)|^[8].*/i;
    const aRegex =
      /^([u][n]|[u][n]|[u][b]|[e][uw]|one|uni(on|form|verse|versity)|ut[il])/i;

    if (anRegex.test(firstWord)) {
      return "an";
    }

    if (aRegex.test(firstWord)) {
      return "a";
    }

    if (/^[aeiou]/i.test(firstWord)) {
      return "an";
    }

    return "a";
  },
};
